import { useState } from "react"
import styled from "styled-components"

import { useDebouncedCallback } from "use-debounce"

import { SearchFilter } from "src/components/Filter/SearchFilter"
import { UpdateAirbnbIntegrationBanner } from "src/components/Integrations/AirbnbUpdate/UpdateAirbnbIntegrationBanner"
import { LinkHomeTable } from "src/components/Integrations/Integration/LinkHomeTable"
import { CreateLinkDialog } from "src/components/Integrations/IntegrationConnectDialogs/CreateLinkDialog"
import { UnlinkDialog } from "src/components/Integrations/IntegrationConnectDialogs/UnlinkDialog"
import {
  NoHomes,
  NoHomesOnFilter,
} from "src/components/Integrations/IntegrationHomesEmptyState"
import { Pager } from "src/components/Pager/Pager"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import {
  usePostIntegrationHomeSearchedEvent,
  usePostIntegrationLinkHomeInitiatedEvent,
} from "src/data/analytics/queries/integrationAnalyticsQueries"
import { HOME_LIST_LIMIT } from "src/data/homes/logic/homeConstants"
import { useTrackIntegrationViewedEvent } from "src/data/integrations/hooks/useSendIntegrationEvents"
import {
  checkBrokenIntegration,
  filterUnlinkedIntegrationEntites,
} from "src/data/integrations/logic/integrations"
import { useFetchIntegrationEntities } from "src/data/integrations/queries/integrationEntityQueries"
import {
  useFetchIntegrationHomeLinks,
  useFetchIntegrationLinks,
} from "src/data/integrations/queries/integrationLinkQueries"
import {
  OwnerType,
  TIntegration,
} from "src/data/integrations/types/integrationTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHomes } from "src/data/organizations/queries/homeQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

import { GotoMultiAccountSettingsBanner } from "./GotoMultiAccountSettingsBanner"

export function LinkHomesTab({
  integration,
  tabId,
}: {
  integration: TIntegration
  tabId: string
}) {
  const { t } = useTranslate()
  const { orgId } = useOrganization()
  const { limit, offset, setOffset } = useUrlPager({
    initialLimit: HOME_LIST_LIMIT,
  })
  const [searchFilter, setSearchFilter] = useState("")
  const [showCreateLinkDialog, setShowCreateLinkDialog] = useState(false)
  const [showUnlinkDialog, setShowUnlinkDialog] = useState(false)
  const [createLinkProps, setCreateLinkProps] = useState<{
    homeId: string
    homeName: string
  }>()
  const [unlinkProps, setUnlinkProps] = useState<{
    homeName: string
    entityName: string
    linkId: string
  }>()

  const postLinkHomeInitiated = usePostIntegrationLinkHomeInitiatedEvent()
  const postIntegrationHomeSearched = usePostIntegrationHomeSearchedEvent()

  const postIntegrationHomeSearchedDebounced = useDebouncedCallback(() => {
    postIntegrationHomeSearched.mutate({ integration_name: integration.name })
  })

  const isBrokenIntegration = checkBrokenIntegration(integration)

  const fetchIntegrationEntities = useFetchIntegrationEntities({
    integration: integration.integration_identifier,
    orgId,
    ownerType: OwnerType.ORGANIZATION,
    options: {
      enabled: !isBrokenIntegration,
    },
  })

  const integrationEntities = fetchIntegrationEntities.data?.entities ?? []

  const fetchIntegrationHomeLinks = useFetchIntegrationHomeLinks({
    integrationId: integration.integration_identifier,
    ownerId: orgId,
    ownerType: OwnerType.ORGANIZATION,
    filters: {
      limit,
      offset,
      name: searchFilter || undefined,
    },
  })

  const homeLinks = fetchIntegrationHomeLinks.data?.homes ?? []

  const fetchHomes = useFetchHomes({
    orgId,
    filters: {
      limit: homeLinks.length,
      ids: homeLinks.map((h) => h.home_id),
    },
    options: { enabled: homeLinks.length > 0 },
  })

  const homes = fetchHomes.data?.homes ?? []

  const fetchIntegrationLinks = useFetchIntegrationLinks(
    {
      owner_id: orgId,
      integration: integration.integration_identifier,
      owner_type: OwnerType.ORGANIZATION,
    },
    { enabled: integrationEntities.length > 0 }
  )

  const totalHomeLinksCount = fetchIntegrationHomeLinks.data?.paging.total_count
  const integrationLinks = fetchIntegrationLinks.data?.links ?? []
  const unlinkedEntities = filterUnlinkedIntegrationEntites(
    integrationEntities,
    integrationLinks
  )

  const loading =
    fetchHomes.isInitialLoading ||
    fetchIntegrationEntities.isInitialLoading ||
    fetchIntegrationLinks.isInitialLoading ||
    fetchIntegrationHomeLinks.isLoading

  const showNoHomes = !loading && homeLinks.length < 1

  function onCloseDialog() {
    setShowCreateLinkDialog(false)
    setShowUnlinkDialog(false)
    setUnlinkProps(undefined)
    setCreateLinkProps(undefined)
  }

  function handleSearchChange(value: string) {
    setSearchFilter(value)
    if (!!value) {
      postIntegrationHomeSearchedDebounced()
    }
  }

  useTrackIntegrationViewedEvent({
    integration,
    tabId,
  })

  return (
    <div>
      <SearchFilterBox>
        <UpdateAirbnbIntegrationBanner integration={integration} />
        <GotoMultiAccountSettingsBanner integration={integration} />
        <SearchWrapper>
          <SearchFilter
            placeholder={t(langKeys.search_for_home)}
            onChange={handleSearchChange}
            delay={500}
            disabled={loading}
          />
        </SearchWrapper>
      </SearchFilterBox>
      <IntegrationConfigureBox>
        <LinkHomeTable
          homes={homes}
          integrationEntities={integrationEntities}
          homeLinks={homeLinks}
          loading={loading}
          integration={integration}
          onLinkHome={({ homeId, homeName }) => {
            postLinkHomeInitiated.mutate({
              context: "integration_page",
              integration_name: integration.name,
            })
            setCreateLinkProps({ homeId, homeName })
            setShowCreateLinkDialog(true)
          }}
          onRemoveLink={({ linkId, entityName, homeName }) => {
            setUnlinkProps({ linkId, entityName, homeName })
            setShowUnlinkDialog(true)
          }}
        />
        <PagerWrapper>
          <Pager
            limit={limit}
            offset={offset}
            setOffset={setOffset}
            totalCount={totalHomeLinksCount}
          />
        </PagerWrapper>
        {showNoHomes &&
          (searchFilter ? (
            <NoHomesOnFilter />
          ) : (
            <NoHomes
              body={t(langKeys.integrations_create_homes_to_get_started_link)}
            />
          ))}
      </IntegrationConfigureBox>

      {createLinkProps && (
        <CreateLinkDialog
          integration={integration}
          homeName={createLinkProps.homeName}
          homeId={createLinkProps.homeId}
          open={showCreateLinkDialog}
          unlinkedEntities={unlinkedEntities}
          onClose={onCloseDialog}
        />
      )}

      {unlinkProps && (
        <UnlinkDialog
          integration={integration}
          homeName={unlinkProps.homeName}
          entityName={unlinkProps.entityName}
          linkId={unlinkProps.linkId}
          open={showUnlinkDialog}
          onClose={onCloseDialog}
        />
      )}
    </div>
  )
}

const SearchFilterBox = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const IntegrationConfigureBox = styled.div`
  margin-top: ${spacing.M};
`

const PagerWrapper = styled.div`
  margin-top: ${spacing.XL};
`

const SearchWrapper = styled.div`
  max-width: 30ch;
`
